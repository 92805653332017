/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import store from 'store'
import { allowedRoles } from 'entities/user'
import { BASE_URL } from 'api/api-connection'
import { useReFetch } from 'api/useFetch'
import squaresGreen from '../../../assets/svg/squares-green.svg'
import squaresPurple from '../../../assets/svg/squares-purple.svg'
import PortalButton from './PortalButton'
// import squares from '../../../assets/svg/squares.svg'
// import {userPlus, Sliders} from ""
import LapzoButton from './lapzoButton'
import LookerStudioButton from './LookerButton'

const INTEGRATIONS = {
  LAPZO: 'LAPZO',
  LOOKER_STUDIO: 'LOOKER_STUDIO',
}

const IntegrationButtons = ({ style }) => {
  const [visible, setVisible] = useState(false)
  const { data: company } = useReFetch(
    {
      url: '/companies/self',
      method: 'post',
      body: {
        projection: `configuration.general.integrations configuration.general.employeePortal`,
      },
    },
    [],
  )
  const integrations = company?.data?.configuration?.general?.integrations
  const portal = company?.data?.configuration?.general?.employeePortal

  useEffect(() => {
    localStorage.setItem('INTEGRATIONS', JSON.stringify(integrations))
  }, [integrations])

  const menu = (
    <div className="">
      {portal && <PortalButton />}
      {integrations?.includes(INTEGRATIONS.LAPZO) && <LapzoButton />}
      {integrations?.includes(INTEGRATIONS.LOOKER_STUDIO) && <LookerStudioButton />}
      {/* <a
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        href={`https://recruiting.fichap.com/?token=${store.get('accessToken')}`}
        target="_blank"
        rel="no-referrer"
        className="LapzoButton purple"
      >
        <span> Hiring </span>
        <img src="svg/user-plus.svg" alt="" />
      </a> */}
      {allowedRoles?.includes('fichap') && (
        <a
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            // marginTop: 10,
          }}
          href={`https://admin${
            BASE_URL === 'https://api-test.fichap.com' ? '-dev' : ''
          }.fichap.com/?token=${store.get('accessToken')}`}
          target="_blank"
          rel="no-referrer"
          className="LapzoButton green"
        >
          <img src="svg/sliders.svg" alt="" />
          <span> Admin </span>
        </a>
      )}
    </div>
  )
  return (
    <Dropdown
      className="integrationButtons"
      expandIconPosition=""
      overlay={menu}
      trigger={['click']}
      onVisibleChange={setVisible}
      overlayClassName="menuCollapseDropdown shadow-ml"
    >
      <a
        style={{
          display: 'flex',
          borderRadius: 4,
          background: '#f0f3f4',
        }}
        className="LapzoButton green main"
      >
        <img style={{ marginRight: 11 }} src={squaresGreen} alt="" />
        <span style={{ color: '#007aff' }}> HR Core </span>
      </a>
    </Dropdown>
  )
}

export default IntegrationButtons
